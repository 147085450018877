import React from 'react';

import Layout from '../components/layout/layout';
import Seo from '../components/layout/seo';

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <div className="page__content">
            <div className="container paragraph--type-columns-one container--padded-center">
                <h1>Page not found</h1>
            </div>
        </div>
    </Layout>
);

export default NotFoundPage;
